import { PasswordRules } from '@/interfaces/PasswordRules.interface'
import { getApp } from '@/helpers/feathers'

/**
 * Gibt die Passwortregeln zurück
 *
 * @returns object - Passwortregeln
 */
export async function getPasswordRules(): Promise<PasswordRules> {
  const app = await getApp()

  try {
    return await app.service('password-rules').find()
  } catch (e) {
    // Platzhalter
  }

  return {
    enabled: false,
    forceChangeOnFirstLogin: true,
    maximumLength: 0,
    allowedSpecialCharacters: '',
    minimumDigits: 0,
    minimumLength: 0,
    minimumLowerCase: 0,
    minimumSpecialCharacters: 0,
    minimumUpperCase: 0
  }
}

/**
 * Holt die aktuellen Passwort Regeln vom Server und gibt den aus der
 * Fehlermeldung zutreffenden Key zurück
 *
 * @param errorMessage - Fehlermeldung
 * @returns string | null - Key der Fehlermeldung
 */
export async function getPasswordValidationError(
  errorMessage: string
): Promise<string | null> {
  const passwordRules = await getPasswordRules()

  Object.keys(passwordRules)
  for (let i = 0; i < Object.keys(passwordRules).length; i++) {
    if (errorMessage.includes(Object.keys(passwordRules)[i])) {
      return errorMessage.slice(0, errorMessage.indexOf(':'))
    }
  }
  return null
}
