
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getApp } from '@/helpers/feathers'
import { getDashboardID } from '@/helpers/dashboard'
import { i18n } from '../../plugins/i18n'
import LanguagePicker from '@/components/LanguagePicker/LanguagePicker.vue'
import ErrorableCard from '@/components/ErrorableCard/ErrorableCard.vue'
import { GenericErrorData } from '@/interfaces/GenericErrorData.interface'
import {
  getPasswordValidationError,
  getPasswordRules
} from '@/helpers/get-password-rule-error'
import { NotificationPayload } from '@/store/notifications/notification-payload.interface'
import { namespace } from 'vuex-class'

const NotificationStore = namespace('notification')

/**
 * Password Token zum Zurücksetzen des Passworts.
 */
interface PasswordToken {
  /**
   * Token
   */
  passwordToken: string
  /**
   * Mitarbeiter ID
   */
  employeeID: number
}

/**
 * Maske, die es dem Benutzer ermöglicht sein Passwort zu ändern, falls es noch
 * nicht geändert wurde
 */
@Component({
  components: {
    LanguagePicker,
    ErrorableCard
  }
})
export default class SetPasswordContent extends Vue {
  /**
   * Gibt an, ob das neue Passwort zur Parent-Komponente weitergeleitet werden
   * soll, damit diese sich um das Speichern kümmert.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public sendPasswordToParent!: boolean

  /**
   * ID des Benutzers
   */
  @Prop({ type: Number, required: false, default: undefined })
  public userId?: number

  /**
   * Gibt an, ob die Komponente die volle verfügbare Breite nutzt oder auf 600pt
   * limitiert ist
   */
  @Prop({ type: Boolean, required: false, default: false })
  public full!: boolean

  /**
   * Gibt an, ob zur Bestätigung eine erneute Eingabe des Passworts erforderlich
   * ist.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public confirmPassword!: boolean

  /**
   * Gibt an, ob nach dem erfolgreichen Setzen eines Passworts, der Benutzer
   * zurück auf die letzte Seite geleitet wird. Alternativ wird er auf sein
   * Standard Dashboard geleitet.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public returnToLastPage!: boolean

  /**
   * ID des Benutzers. Ist zunächst die übergebene userId, kann jedoch durch die
   * Mitarbeiter-ID aus dem Token überschrieben werden.
   */
  public localUserId?: number

  /**
   * Überschrift des Dialogs
   */
  @Prop({
    type: String,
    required: false,
    default: i18n.t('setPasswordContent.password.setPasswordTitle')
  })
  public title?: string

  /**
   * Token, falls das Passwort vergessen wurde.
   */
  @Prop({
    type: String,
    required: false
  })
  public token?: string

  /**
   * Gibt an, ob der LanguagePicker angezeigt werden
   * soll
   */
  @Prop({ type: Boolean, required: false, default: true })
  public showLanguagePicker!: boolean

  /**
   * Sprachkey für das Label des Zurückbuttons. Der Button wird
   * ausgeblendet, wenn die Eigenschaft nicht angegeben wird.
   */
  @Prop({
    type: String,
    required: false
  })
  public prevBtnKey?: string

  /**
   * Falls der Token nicht valide ist, dann wird ein Error angezeigt.
   */
  public tokenValid = true

  /**
   * Eingegebenes neues Passwort
   */
  public password = ''

  /**
   * Eingegebenes aktuelles Passwort des Benutzers
   */
  public currentPassword = ''

  /**
   * Zeigt an, ob die Passwortänderung erfolgreich war.
   */
  public success = false

  /**
   * Bestätigung des eingegebenen neuen Passworts
   */
  public passwordConfirmation = ''

  /**
   * Gibt an, ob beim Ändern des Passworts ein Fehler aufgetreten ist
   */
  public setPasswordError = false

  /**
   * Der Sprachstringkey für den passenden Fehlertext
   */
  public setPasswordErrorKey = ''

  /**
   * Gibt an, ob die Eingabe gültig ist
   */
  public valid = false

  /**
   * Gibt an, ob der Token noch überprüft wird
   */
  public loading = true

  /**
   * Gibt an, ob das Passwort momentan geändert wird
   */
  public passwordChangeInProgress = false

  /**
   * Passwortregeln die in der Vue angezeigt werden sollen
   */
  public passwordRules = {}

  /**
   * Fehlerdaten. Der Fehler wird in der Maske angezeigt, wenn dieses Feld
   * gesetzt ist.
   */
  public error: GenericErrorData | null = null

  /**
   * Togglet einen Toaster und zeigt diesen an
   */
  @NotificationStore.Action('toggleToast')
  public toggleToast!: (payload: NotificationPayload) => void

  /**
   * Holt die Passwortrichtlinien vom Backend
   */
  public async created(): Promise<void> {
    const app = await getApp()

    this.passwordRules = await getPasswordRules()

    this.localUserId = this.userId

    if (this.token) {
      let data: PasswordToken

      try {
        data = await app.service('password-token-check').find({
          query: {
            token: this.$route.params.token
          }
        })
      } catch (err) {
        if (err.data.errorCode > 0 || err.data.errorCode === 0) {
          this.error = {}
          this.error.code = err.data.errorCode

          if (err.message) {
            this.error.replacements = { message: err.message }
          }
          if (err.data.details) {
            this.error.replacements = {
              details: JSON.stringify(err.data.details)
            }
          }
        }
        this.loading = false
        this.tokenValid = false
        throw err
      }
      if (data.employeeID > 0) {
        this.localUserId = data.employeeID
        this.loading = false
      }
    }
    this.loading = false
  }

  /**
   * Ändert das Passwort des Benutzers und reagiert auf verschiedene Fehler,
   * die dabei entstehen können
   *
   * @param event - das Event des Submit Buttons
   * @param valid - Boolean, ob Eingabemaske gültig ist
   */
  public async changePassword(event: Event, valid: boolean): Promise<void> {
    // Form Submit unterbinden.
    if (typeof event !== 'undefined') {
      event.preventDefault()
    }

    if (!valid) {
      return
    }

    if (this.localUserId === 0 || this.localUserId === undefined) {
      this.setPasswordError = true
      this.setPasswordErrorKey = 'setPasswordContent.error.general'
      return
    }

    let data = { passwordNew: this.password }

    if (this.token) {
      data = { ...data, ...{ passwordToken: this.token } }
    }

    if (this.confirmPassword) {
      data = { ...data, ...{ passwordOld: this.currentPassword } }
    }

    if (this.sendPasswordToParent) {
      this.$emit('changepassword', this.password)
      return
    }

    this.passwordChangeInProgress = true
    try {
      const passwordChanged = await (await getApp())
        .service('password-change')
        .patch(this.localUserId, data)

      localStorage.removeItem('changePwFirst')

      this.passwordChangeInProgress = false

      if (this.token) {
        if (passwordChanged.passwordToken === this.token) {
          this.success = true
        } else {
          this.setPasswordError = true
          this.setPasswordErrorKey = 'setPasswordContent.error.general'
        }

        return
      }

      if (this.returnToLastPage) {
        const payload: NotificationPayload = {
          type: 'success',
          text: this.$t('setPasswordContent.password.success').toString()
        }
        this.toggleToast(payload)
        this.$router.go(-1)
      } else {
        const userDashboardId = await getDashboardID()

        if (userDashboardId === null) {
          window.location.href = window.location.origin + '/index.php/0/eplas'
        } else {
          this.$router.push(`/dashboard/${userDashboardId}`)
        }
      }
    } catch (e) {
      this.passwordChangeInProgress = false
      let passwordValidationError = null

      passwordValidationError = await getPasswordValidationError(e.message)
      this.setPasswordError = true

      this.setPasswordErrorKey = passwordValidationError
        ? 'setPasswordContent.error.' + passwordValidationError
        : 'setPasswordContent.error.general'
    }
  }

  /**
   * Definiert die Regeln für die Eingabe des neuen Passworts
   *
   * @returns Regeln für das Passwordfeld
   */
  public get currentPasswordRules(): ((v: boolean) => string | true)[] {
    return [
      (v: boolean): true | string =>
        !!v || this.$t('passwordChange.error.requiredField').toString()
    ]
  }

  /**
   * Definiert die Regeln für die Eingabe des neuen Passworts
   *
   * @returns Regeln für das Passwordfeld
   */
  public get newPasswordRules(): ((v: boolean) => string | true)[] {
    return [
      (v: boolean): true | string =>
        !!v || this.$t('loginMask.error.passwordValidation').toString()
    ]
  }

  /**
   * Definiert die Regeln für die Eingabe der Bestätigung des neuen Passworts
   *
   * @returns Regeln für das Confirmation-Passwordfeld
   */
  public get passwordConfirmationRules(): [string | true] {
    return [
      this.passwordConfirmation === this.password ||
        this.$t(
          'setPasswordContent.error.passwordConfirmationValidation'
        ).toString()
    ]
  }

  /**
   * Definiert die Regeln für die Eingabe des neuen Passworts
   *
   * @returns Regeln für das Passwordfeld
   */
  public get fullScreenClass(): string {
    if (this.full) {
      return '--full'
    }
    return ''
  }

  /**
   * Leitet auf die Login-Seite weiter.
   */
  public goToLogin(): void {
    this.$router.push('/login')
  }

  /**
   * Event-Handler für den Klick auf den Zurückbutton. Sendet ein
   * "back"-Event.
   */
  public onBackClick(): void {
    this.$emit('back')
  }
}
